export const isDevelopmentMode = import.meta.env.DEV // Global Development Mode
export const BACKEND_Dev_PORT = import.meta.env.DEV === true ? 5000 : 5000 // Global Development Port
export const Live_URL = isDevelopmentMode === true ? `http://localhost:${BACKEND_Dev_PORT}` : isIP(window.location.origin) == true ?`${window.location.origin}:${BACKEND_Dev_PORT}` : window.location.origin  // Global Live URL



function isIP(address) {
    // Regex for IPv4
    const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    // Regex for IPv6
    const ipv6Regex = /^(?:[0-9a-fA-F]{1,4}:){7}(?:[0-9a-fA-F]{1,4}|:)|^(?:[0-9a-fA-F]{1,4}:){1,7}:|^(?:[0-9a-fA-F]{1,4}:){1,6}:(?:[0-9a-fA-F]{1,4}|:)|^(?:[0-9a-fA-F]{1,4}:){1,5}:(?:[0-9a-fA-F]{1,4}:){0,1}(?:[0-9a-fA-F]{1,4}|:)|^(?:[0-9a-fA-F]{1,4}:){1,4}:(?:[0-9a-fA-F]{1,4}:){0,2}(?:[0-9a-fA-F]{1,4}|:)|^(?:[0-9a-fA-F]{1,4}:){1,3}:(?:[0-9a-fA-F]{1,4}:){0,3}(?:[0-9a-fA-F]{1,4}|:)|^(?:[0-9a-fA-F]{1,4}:){1,2}:(?:[0-9a-fA-F]{1,4}:){0,4}(?:[0-9a-fA-F]{1,4}|:)|^[0-9a-fA-F]{1,4}:(?:[0-9a-fA-F]{1,4}:){0,5}(?:[0-9a-fA-F]{1,4}|:)|^:(?:[0-9a-fA-F]{1,4}:){0,6}(?:[0-9a-fA-F]{1,4}|:)|^::$/;

    return ipv4Regex.test(address) || ipv6Regex.test(address);
}
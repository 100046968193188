/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const RelatedProductscard = ({ data }) => {
   const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { price, rating, _id } = data;
  
  return (
    <div>
      <div className="bg-cardBg pb-3 mx-auto shadow-md rounded-lg max-w-80">
        <Link to={`/productDetails/${_id}`}>
          <div className="slide-container rounded-lg">
            <img
              src={data?.links[0]?.url}
              alt=""
              className="h-72 xl:h-[270px] md:h-[260px] lg:h-[280px] rounded-lg"
            />
          </div>
          <h1 className="text-2xl font-bold   text-mainColor">{data?.name}</h1>
        <div className="p-2">
          <div className="">
            <h1 className="font-bold text-xl text-black">
              ₹ <span> {price}</span>
            </h1>
            <h1 className="font-bold text-black">
              Ratings : <span>{rating}</span>
            </h1>
          </div>
        </div>
        </Link>
      </div>
    </div>
  );
};

export default RelatedProductscard;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CategoryCard from "./CategoryCard";
import { useLocation } from "react-router-dom";
import { Live_URL } from "../../../config";

const CategoryProduct = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [category, setCategory] = useState([])

  useEffect(() => {
    fetch(`${Live_URL}/api/category`).then(res => res.json()).then(data => {
      setCategory(data)
    })
  }, [])


  const categoryProdact = [
    {
      _id: "11",
      imgs: [
        { url: "https://i.ibb.co/h7Pwk7m/download.jpg" },
        { url: "https://i.ibb.co/S38n5Lf/download-1.jpg" },
      ],
      title: "Bell metal productsKansa products",
    },
    {
      _id: "222",
      imgs: [
        { url: "https://i.ibb.co/h7Pwk7m/download.jpg" },
        { url: "https://i.ibb.co/S38n5Lf/download-1.jpg" },
      ],
      title: "Brass metal products Pital products",
    },
    {
      _id: "3333",
      imgs: [
        { url: "https://i.ibb.co/h7Pwk7m/download.jpg" },
        { url: "https://i.ibb.co/S38n5Lf/download-1.jpg" },
      ],
      title: "copper products products",
    },
  ];

  return (
    <div>
      <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 bg-white">
        {category?.map((data) =>
          <CategoryCard data={data} key={data._id} />
        )}
      </div>
    </div>
  );
};

export default CategoryProduct;

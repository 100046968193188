import useAuth from "../../Hooks/UseAuth";


const CheckEmailNotify = () => {
    const { user } = useAuth();
    return (
        <>
            <div className="container mx-auto p-4 mt-8">
                {user?.emailVerified ?
                    <div className="bg-gray-200 p-6 rounded-md">
                        <h1 className="text-3xl text-green-700 mb-6 font-bold">Email verification complete!</h1>
                        <p className="text-xl font-medium">You can now sign in with your new account</p>
                    </div>
                    : <div className="bg-gray-200 p-4 rounded-md">
                        <h2 className="text-3xl font-bold mb-3">Check your email</h2>
                        <p className="text-lg">{"We've"} sent you an email with a verification link. Please check <strong>inbox or spam </strong> folder & verify your email to complete the sign-up process.</p>
                    </div>
                }
            </div >

        </>
    );
};

export default CheckEmailNotify;
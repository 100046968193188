/* eslint-disable react/no-unescaped-entities */


const TermsConditions = () => {
    return (
      <div className="bg-gray-100 p-8 max-w-5xl mx-auto mt-8 rounded-lg shadow-lg">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Terms & Conditions</h1>

      {/* Overview Section */}
      <div className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">1. Overview</h2>
        <div className="space-y-4 text-gray-600">
          <p>
            <strong>Terms and Conditions Acceptance:</strong> Please read these Terms and Conditions ("Terms," "Terms and Conditions") carefully before using our website <a href="http://www.kansapital.com" className="text-blue-500 hover:text-blue-700">www.kansapital.com</a> ("website") or purchasing products from Kansapital ("we," "our," or "us"). These Terms and Conditions govern your access to and use of our website and services. By accessing or using our website or purchasing products from us, you agree to be bound by these Terms and Conditions.
          </p>
          <p>
            <strong>Account Responsibility:</strong> You agree that any use of the Kansapital website is at your own risk. If you use our website, you are responsible for maintaining the confidentiality of your account and password, and for restricting access to your account/password. You are also responsible for all activities that occur under your account. Please take all necessary steps to ensure that your password is kept confidential and secure. Kindly inform us immediately if you believe that your password has become known to anyone else or if it is being used in an unauthorized manner.
          </p>
        </div>
      </div>

      {/* Payment and Payment Methods Section */}
      <div className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">2. Payment and Payment Methods</h2>
        <div className="space-y-4 text-gray-600">
          <p>
            <strong>Payment Transaction:</strong> For completing the payment transaction, Kansapital will share your payment instrument details with payment gateway partners. It is clarified that Kansapital does not store any of your payment instrument details. Any collection, use, storage, or further use shall be subject to your consent and in compliance with applicable guidelines issued by the Reserve Bank of India (RBI) from time to time.
          </p>
          <p>
            <strong>Order Value Limitation:</strong> If your order value exceeds ₹100,000, please note that you may only open the packaging after payment has been received.
          </p>
        </div>
      </div>

      {/* Using Personal Information Section */}
      <div className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">3. Using Personal Information</h2>
        <p className="text-gray-600">
          <strong>Purpose of Personal Information:</strong> We use your Personal Information to provide our services to you, which include offering products for sale, processing payments, shipping and fulfilling your order, and keeping you informed about new products, services, and offers.
        </p>
      </div>

      {/* Cookies Section */}
      <div className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">4. Cookies</h2>
        <p className="text-gray-600">
          A cookie is a small piece of data downloaded to your computer or device when you visit our site. We use various types, like functional, performance, advertising, and social media cookies, to enhance your browsing experience. Cookies remember your actions and preferences (such as login details and region selection), so you don’t need to re-enter them each time you visit or move between pages. They also help us understand how people use our site, whether it’s their first visit or they are frequent visitors.
        </p>
      </div>

      {/* Bulk Orders Section */}
      <div className="mb-10">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Bulk Orders</h2>
        <div className="space-y-4 text-gray-600">
          <p>
            <strong>Advance Payment:</strong> For bulk orders, a 60% payment of the total cost is required before processing.
          </p>
          <p>
            <strong>Non-Refundable:</strong> Please note that bulk orders are non-refundable once payment has been made.
          </p>
          <p>
            We strive to minimize delays in shipments. However, please understand that unforeseen circumstances may impact delivery timelines.
          </p>
        </div>
      </div>

      {/* Contact Section */}
      <div className="text-gray-600">
        <p>For any inquiries or assistance regarding shipping, please contact our customer support team. We're here to ensure your satisfaction!</p>
      </div>
    </div>
    );
};

export default TermsConditions;
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import CategoryAllProductcard from "./CategoryAllProductcard";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Live_URL } from "../../../config";

const CategoryAllProduct = () => {
  const { category } = useParams();
  const [categories, setCategories] = useState([])
 

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // load product by category
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${Live_URL}/api/category/${category}`);
        setCategories(res.data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };
    
    fetchData();
  }, [category])


  return <div>
    <div className="my-5">
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 mx-auto grid-cols-1 gap-6">
        {categories.map((data) =>
          <CategoryAllProductcard data={data} key={data._id} />
        )}
      </div>
    </div>
  </div>;
};

export default CategoryAllProduct;

/* eslint-disable no-unused-vars */
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { useEffect, useState } from "react";
import { BiCartAdd } from "react-icons/bi";
import useAuth from "../../Hooks/UseAuth";
import { getLocaldata } from "./LocalStorage";
import mainLogo from "../../assets/mainLogo.png";
import profilePic from "../../assets/profile-pic10.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [products, setProducts] = useState([]);
  const { user, logOut, maulLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const product = getLocaldata();
    setProducts(product);
  }, [maulLoading]);

  const productLength = products.length;

  const handleLogout = () => {
    logOut();
    navigate('/');
  };

  return (
    <div className="navbar bg-slate-50 p-2 rounded-xl shadow-lg shadow-gray-300">
      <div className="navbar-start">
        <Link to="/" className="btn btn-ghost normal-case text-xl">
          <img src={mainLogo} alt="mainLogo" className="w-[2.5rem]" />
        </Link>
      </div>

      {/* Search Bar */}
      <div className="navbar-center hidden md:flex">
        <div className="relative">
          <input
            type="text"
            className="input input-bordered w-full md:w-[400px] lg:w-[550px] xl:w-[600px] max-w-full border-black text-black bg-white"
            placeholder="Search"
            value={searchData}
            onChange={(e) => setSearchData(e.target.value)}
          />
          <Link to={`/search-Product/${searchData}`} className="absolute right-4 top-1/2 transform -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-5 h-5 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
        </div>
      </div>

      <div className="navbar-end">
        <ul className="menu menu-horizontal p-0 hidden lg:flex text-black font-semibold">
          <li>
            <NavLink to="/home">Home</NavLink>
          </li>
          <li>
            <NavLink to="/shop">Shop</NavLink>
          </li>
          <li>
            <NavLink to="/cart">
              <div className="indicator">
                <BiCartAdd className="font-bold text-2xl" />
                {productLength !== 0 && (
                  <span className="indicator-item badge badge-secondary">
                    {productLength}
                  </span>
                )}
                <span className="py-1">Cart</span>
              </div>
            </NavLink>
          </li>
          {user?.uid ? (
            <li className="dropdown dropdown-hover dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="flex items-center gap-1"
                onClick={() => setIsProfileOpen(!isProfileOpen)}
              >
                <div className="avatar">
                  <div className="w-6 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                    <img src={profilePic} alt="Profile" />
                  </div>
                </div>
                <IoIosArrowDown />
              </div>
              {isProfileOpen && (
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[1] menu p-2 w-52 bg-slate-50 rounded-xl shadow-lg shadow-gray-300"
                >
                  <li className="border-b-2 border-gray-300">
                    <NavLink to="/editProfile" onClick={() => setIsProfileOpen(false)}>Edit Profile</NavLink>
                  </li>
                  <li className="border-b-2 border-gray-300">
                    <NavLink to="/yourOrders" onClick={() => setIsProfileOpen(false)}>Your Orders</NavLink>
                  </li>
                  <button
                    onClick={handleLogout}
                    className="bg-red-700 px-2 py-2 text-center w-28 mt-2 mx-auto hover:bg-red-600 rounded-md"
                  >
                    Log Out
                  </button>
                </ul>
              )}
            </li>
          ) : (
            <li>
              <NavLink
                to="/login"
                className="bg-blue-600 text-white hover:bg-blue-500"
              >
                LogIn
              </NavLink>
            </li>
          )}
        </ul>

        {/* Mobile Menu */}
        <div className="dropdown dropdown-end lg:hidden">
          <label
            tabIndex={0}
            className="btn btn-ghost"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
              />
            </svg>
          </label>
          {isMenuOpen && (
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content mt-3 bg-slate-50 rounded-xl shadow-md shadow-gray-300 w-52 text-black"
            >
              <li className="border-b-2 border-gray-300">
                <NavLink to="/home" onClick={() => setIsMenuOpen(false)}>Home</NavLink>
              </li>
              <li className="border-b-2 border-gray-300">
                <NavLink to="/shop" onClick={() => setIsMenuOpen(false)}>Shop</NavLink>
              </li>
              <li className="border-b-2 border-gray-300">
                <NavLink to="/cart" onClick={() => setIsMenuOpen(false)}>
                  <div className="indicator">
                    <BiCartAdd className="font-bold text-2xl" />
                    {productLength !== 0 && (
                      <span className="indicator-item badge badge-secondary">
                        {productLength}
                      </span>
                    )}
                    <span className="py-1">Cart</span>
                  </div>
                </NavLink>
              </li>
              {user?.uid ? (
                <>
                  <li className="border-b-2 border-gray-300">
                    <details>
                      <summary className="flex items-center">
                        <div className="avatar">
                          <div className="w-6 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                            <img src={profilePic} alt="Profile" />
                          </div>
                        </div>
                        <IoIosArrowDown />
                      </summary>
                      <ul className="p-2 bg-slate-100 text-neutral-950 shadow-lg shadow-gray-300">
                        <li className="border-b-2 border-gray-300">
                          <NavLink to="/editProfile" onClick={() => setIsMenuOpen(false)}>Edit Profile</NavLink>
                        </li>
                        <li className="border-b-2 border-gray-300">
                          <NavLink to="/yourOrders" onClick={() => setIsMenuOpen(false)}>Your Orders</NavLink>
                        </li>
                      </ul>
                    </details>
                  </li>
                  <li>
                    <button
                      onClick={handleLogout}
                      className="bg-red-700 px-2 mt-2 pt-2 w-28 mx-auto text-center hover:bg-red-600 rounded-md"
                    >
                      Log Out
                    </button>
                  </li>
                </>
              ) : (
                <li>
                  <NavLink
                    to="/login"
                    className="bg-blue-600 text-white hover:bg-blue-500"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    LogIn
                  </NavLink>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

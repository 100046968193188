/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { FaDownload } from "react-icons/fa";
import useAuth from "../../Hooks/UseAuth";
import { BlobProvider } from "@react-pdf/renderer";
import OrderInvoice from "./OrderInvoice";
import { GrView } from "react-icons/gr";
import OrderModal from "./OrderModal";
import { Live_URL } from "../../config";
import Swal from "sweetalert2";

const YourOrders = () => {
  const [orders, setOrders] = useState([]);
  const [findItems, setFindItems] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { user } = useAuth();


//  console.log(orders);
  const handleSelectOrder = (id) => {
    const findOrder = orders.find((o) => o._id === id);
    setSelectedOrder(findOrder);
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${Live_URL}/api/order/yourOrder/${user?.email}`
      );
      const reversedData = res.data.reverse();
      setOrders(reversedData);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user?.email, selectedOrder]);

  const handelclick = (items) => {
    setFindItems(items);
    document.getElementById("my_modal_5").showModal()
  }

  return (
    <div>
      <div className="mx-4 mt-8">
        <h1 className="text-3xl  font-bold mb-6">Your Orders</h1>

        <div className="overflow-x-auto  shadow  mt-4 rounded-md bg-white">
          <table className="table w-full table-sm">
            <thead>
              <tr className="font-semibold text-base">
                <th>Date</th>
                <th>Order ID</th>
                <th>PaymentID</th>

                <th>Items</th>
                <th>Total</th>
                <th>Status</th>
                <th>Invoice</th>
                <th>View</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((order) => (
                <tr key={order._id}>
                  {
                    console.log(order)
                  }
                  <td>{order.orderDate}</td>
                  <td>{order.order_id ? order.order_id : order._id}</td>
                  <td>{order.razorpay?.razorpay_payment_id}</td>

                  <td>{order.items.length}</td>
                  <td>₹{order.totalAmount}</td>
                  <td>
                    <span
                      className={`
                                    ${
                                      order.status === "Order Placed" &&
                                      "badge-warning"
                                    } 
                                    ${
                                      order.status === "Ongoing" && "badge-info"
                                    } 
                                    ${
                                      order.status === "Delivery" &&
                                      "badge-success"
                                    } 
                                    ${
                                      order.status === "Canceled" && "badge-error"
                                    } 
                                     font-medium py-1 px-2 rounded-lg`}
                    >
                      {order.status}
                    </span>
                  </td>
                  <td>
                    <button onClick={() => handleSelectOrder(order._id)}>
                      <BlobProvider
                        document={
                          <OrderInvoice
                            selectedOrder={selectedOrder}
                            fetchData={fetchData}
                          />
                        }
                        fileName="OrderInvoice.pdf"
                      >
                        {({ url }) => (
                          <a href={url} target="_blank">
                            <FaDownload
                              size={24}
                              className="text-yellow-500 hover:text-yellow-400 ml-3"
                            />
                          </a>
                        )}
                      </BlobProvider>
                    </button>
                  </td>
                  <td>
                    <GrView
                      onClick={()=>handelclick(order.items)}
        // onClick={() => document.getElementById("my_modal_5").showModal()}
      />
                  </td>
                  <td>
                    {order.status.toLowerCase() == "order placed" && <button
                      className="bg-red-500 text-white px-2 py-1 rounded-lg"
                      onClick={() => {
                       Swal.fire({
                          title: 'Are you sure?',
                          text: "You won't be able to revert this!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Yes, cancel it!'
                       }).then((result) => {
                          if (result.isConfirmed) {
                            axios
                            .patch(`${Live_URL}/api/order/cancel/${order._id}`)
                            .then(() => fetchData());
                          }
                        })
                      }}
                    >
                      Cancel
                    </button>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
              <OrderModal  items={findItems}/>

      {/* Open the modal using document.getElementById('ID').showModal() method */}
     

      {/* <Billing orders={orders}/> */}
    </div>
  );
};

export default YourOrders;

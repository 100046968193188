/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';

const AboutUs = () => {
    return (
        <div className='grid xl:grid-cols-1 lg:grid-cols-1 gap-10 xl:px-20 lg:px-14 md:px-10 px-5'>
            <div>
                <div>
                    <h1 className='font-bold text-3xl text-darkblue my-4'>✨ ABOUT US ✨</h1>
                    <h1 className='font-semibold text-5xl text-darkblue'>Welcome to Our Platform</h1>
                    <p className='my-4 text-lg text-black'>
                        Welcome to our <span className='text-black font-semibold'>e-commerce platform</span>, where we celebrate the timeless tradition of <span className='text-black font-semibold'>Kansa</span> (bronze) and <span className='text-black font-semibold'>Pital</span> (brass) utensils. With roots stretching back to the Bronze Age (around 3300 to 1200 BCE), these metals have been cherished for centuries for their remarkable properties and cultural significance. Kansa, an alloy of copper and tin, and Pital, an alloy of copper and zinc, were not only vital in the creation of durable tools, religious idols, and musical instruments but also played a crucial role in everyday life.
                    </p>
                </div>
                <div className='mt-10'>
                    <h2 className='font-bold text-3xl text-darkblue my-4'>🎯 Our Mission</h2>
                    <p className='text-lg text-black mb-4'>
                        At <a href="https://www.kansapital.com/">kansapital.com</a>, our mission is to revive the age-old tradition of nutritious cooking in brassware. Cooking in brassware preserves the maximum nutritional value of food, ensuring that every meal remains wholesome and healthy. Rooted in <span className='text-black font-semibold'>Ayurvedic principles</span>, this traditional method enhances the king component of food, promoting wellness and vitality through every meal.
                    </p>
                </div>

                <div className='mt-10'>
                    <h2 className='font-bold text-3xl text-darkblue my-4'>🌟 Our Vision</h2>
                    <p className='text-lg text-black mb-4'>
                        At <a href="https://www.kansapital.com/">kansapital.com</a>, we envision a healthier world empowered by the benefits of copper. Copper's natural properties aid in breaking down and eliminating fat, facilitating weight loss. It boosts immunity and promotes gut health, contributing to overall well-being and vitality.
                    </p>
                </div>

                <div className='mt-10'>
                    <h2 className='font-bold text-3xl text-darkblue my-4'>💡 Discover the Benefits</h2>
                    <ul className='list-disc list-inside text-lg text-black'>
                        <li><span className='text-black font-semibold'>Optimal Thyroid Function:</span> Copper traces in bronze support the optimal functioning of thyroid glands.</li>
                        <li><span className='text-black font-semibold'>Enhanced Brain Stimulation:</span> Regular use of bronze enhances anti-convulsive properties, stimulating brain function.</li>
                        <li><span className='text-black font-semibold'>Intellectual Enhancement:</span> According to Sanskrit tradition, "Kansyam Buddhivardhakam" signifies that kansa metal benefits our intellect.</li>
                        <li><span className='text-black font-semibold'>Symbol of Value:</span> Anciently known as "Lakshmipradham," kansa metal is revered for its value equivalent to Goddess Lakshmi.</li>
                        <li><span className='text-black font-semibold'>Skin Health:</span> Copper's role in melanin production benefits skin quality and promotes healthy regeneration, supported by kansa, an alloy of copper and tin.</li>
                        <li><span className='text-black font-semibold'>Anti-Aging Properties:</span> Regular use of kansa utensils and kansa thali slows down the aging process.</li>
                        <li><span className='text-black font-semibold'>Hygienic Benefits:</span> Bronze pots and pans possess natural antimicrobial properties, making them the most hygienic choice for kitchen use.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;

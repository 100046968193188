/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Live_URL } from '../../config';

const SlidingImg = () => {
  const [slider, setSlider] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    fetch(`${Live_URL}/api/slider`)
      .then(res => res.json())
      .then(data => {
        setSlider(data);
      })
      .catch(() => {
        setLoad(!load);
      });
  }, [load]);

  return (
    <div className="slide-container">
      {slider.length > 0 && (
        <Slide>
          {slider.map((slideImage, index) => (
            <div key={index} className='flex justify-center items-center'>
              <div 
                style={{ 
                  backgroundImage: `url(${slideImage?.image?.url})`, 
                  backgroundSize: 'cover', 
                  backgroundPosition: 'center',
                  borderRadius: '20px'
                }} 
                className='w-full h-80 sm:h-96 md:h-[500px] lg:h-[600px] xl:h-[700px] rounded-lg'
              >
              </div>
            </div>
          ))}
        </Slide>
      )}
    </div>
  );
};

export default SlidingImg;

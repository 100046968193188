/* eslint-disable react/no-unescaped-entities */
const ContactUs = () => {
  return (
    <div className="bg-gray-50 p-8 max-w-4xl mx-auto mt-8 rounded-lg shadow-lg">
      <h1 className="text-5xl font-bold text-center text-gray-800 mb-10">Contact Us</h1>

      <p className="text-lg text-gray-700 mb-6">
        Thank you for visiting Kansapital! We strive to ensure your satisfaction with every interaction. If you have any questions, concerns, or need assistance, feel free to reach out to us. We're here to help!
      </p>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Our Contact Information</h2>
        <p className="text-lg text-gray-600">
          <strong>E-mail:</strong> 
          <a href="mailto:crm@kansapital.com" className="text-blue-500 hover:text-blue-700 ml-2">crm@kansapital.com</a>
        </p>
      </div>

      <div className="mt-10 text-center">
        <p className="text-lg text-gray-600">
          We look forward to assisting you and making your experience with Kansapital a pleasant one.
        </p>
      </div>
    </div>
  );
};

export default ContactUs;

import { Outlet } from "react-router-dom";
import Navebar from "../Components/Shared/Navebar";
import Footer from "../Components/Shared/Footer";

const MainLayout = () => {
  return (
    <div className="relative">
      <div className="sticky top-0 z-50">
        <Navebar />
      </div>
      <div className="min-h-screen">
        <Outlet />
      </div>
      <div className="-mb-12">
        <Footer/>
      </div>
    </div>
  );
};

export default MainLayout;
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CategoryProdact from '../../Components/Product/CategoryProduct/CategoryProduct';

const Shop = () => {
     const { pathname } = useLocation();
    useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

    return (
        <div className='my-6'>
            <CategoryProdact/>
        </div>
    );
};

export default Shop;
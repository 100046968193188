import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../Pages/Home/Home";
import Cart from "../Pages/Cart/Cart";
import Shop from "../Pages/Shop/Shop";
import CategoryAllProduct from "../Components/Product/CategoryAllProduct/CategoryAllProduct";
import ProductDetails from "../Components/Product/ProductDetails/ProductDetails";
import DashboardLayout from "../layouts/DashboardLayout";
import UserProfile from "../Pages/Dashboard/UserProfile";
import AddProduct from "../Pages/Dashboard/AddProduct/AddProduct";
import EditProfile from "../Pages/EditProfile/EditProfile";
import AddCategory from "../Pages/Dashboard/AddCategory/AddCategory";
import CreateAccount from "../Components/Authentication/CreateAceount";
import Login from "../Components/Authentication/Login";
import Slider from "../Pages/Dashboard/Slider/Slider";
import Orders from "../Pages/Dashboard/Orders/Orders";
import OrderDetails from "../Pages/Dashboard/Orders/OrderDetails";
import DashboardHome from "../Pages/Dashboard/DashboardHome/DashboardHome";
import Users from "../Pages/Dashboard/Users/Users";
import YourOrders from "../Pages/YourOrders/YourOrders";
import AdminLogin from "../Pages/Dashboard/AdminLogin";
import AdminProtectedRoute from "./AdminProtectedRoute";
import Admin from "../Pages/Dashboard/Admin/Admin";
import Search from "../Components/Search/Search";
import CheckEmailNotify from "../Components/Authentication/CheckEmailNotify";
import BulkOrders from "../Pages/Dashboard/BulkOrder/BulkOrder";
import WashingKit from "../Pages/Dashboard/WashingKit/WashingKit";
import BulkOrderForm from "../Components/BulkOrder/BulkOrderForm";
import PrivacyPolicy from "../Components/Product/Policy/PrivacyPolicy";
import ShippingPolicy from "../Components/Product/Policy/ShippingPolicy";
import TermsConditions from "../Components/Product/Policy/TermsConditions";
import AboutUs from "../Components/AboutUs/AboutUs";
import { Live_URL } from "../config";
import ContactUs from "../Components/Product/Policy/ContactUs";
import ReturnAndExchange from "../Components/Product/Policy/Return&Excenge";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: "Error",
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/shop",
        element: <Shop />,
      },
      {
        path: "/products/:category",
        element: <CategoryAllProduct />,
      },
      {
        path: "/productDetails/:id",
        element: <ProductDetails />,
        loader: async ({ params }) =>
          fetch(
            `${Live_URL}/api/product/${params.id}`
          ),
      },
      {
        path: "/editProfile",
        element: <EditProfile />,
      },
      {
        path: "/createAccount",
        element: <CreateAccount />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/check-email",
        element: <CheckEmailNotify />,
      },
      {
        path: "/yourOrders",
        element: <YourOrders />,
      },
      {
        path: "/BulkOrder",
        element: <BulkOrderForm />,
      },
      
      {
        path: "/PrivacyPolicy",
        element: <PrivacyPolicy />,
      },
      
      {
        path: "/ShippingPolicy",
        element: <ShippingPolicy />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/Return&Exchange",
        element: <ReturnAndExchange />,
      },
      {
        path: "/TermsConditions",
        element: <TermsConditions />,
      },
      {
        path: "/AboutUs",
        element: <AboutUs />,
      },
      {
        path: "/search-Product/:id",
        element: <Search />,
        // loader: async ({ params }) => {
        //   fetch(`${Live_URL}/api/products/search?q=${params.Search}`)
        // }
         loader: async ({ params }) =>
          fetch(
            `${Live_URL}/api/products/search?q=${params.id}`
          ),
      
      },
    ],
  },
  {
    path: "/dashboard",
    element: <AdminProtectedRoute><DashboardLayout /></AdminProtectedRoute>,
    children: [
      {
        index: true,
        element: <AdminProtectedRoute><DashboardHome /></AdminProtectedRoute>,
      },
      {
        path: "user",
        element: <AdminProtectedRoute><Users /></AdminProtectedRoute>,
      },
      {
        path: "addCategory",
        element: <AdminProtectedRoute><AddCategory /></AdminProtectedRoute>,
      },
      {
        path: "addProduct",
        element: <AdminProtectedRoute><AddProduct /></AdminProtectedRoute>,
      },
      {
        path: "addSlider",
        element: <AdminProtectedRoute><Slider /></AdminProtectedRoute>,
      },
      {
        path: "orders",
        element: <AdminProtectedRoute><Orders /></AdminProtectedRoute>,
      },
      {
        path: "order/:id",
        element: <AdminProtectedRoute><OrderDetails /></AdminProtectedRoute>,
      },
      {
        path: "profile",
        element: <AdminProtectedRoute><UserProfile /></AdminProtectedRoute>,
      },
      {
        path: "admin",
        element: <AdminProtectedRoute><Admin /></AdminProtectedRoute>,
      },
      {
        path: "bulkOrder",
        element: <AdminProtectedRoute><BulkOrders /></AdminProtectedRoute>,
      },
      {
        path: "add-kit",
        element: <AdminProtectedRoute><WashingKit /></AdminProtectedRoute>,
      },
    ],
  },
  {
    path: "/adminLoginPanel",
    element: <AdminLogin />
  }
]);

export default router;

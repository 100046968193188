/* eslint-disable react/no-unescaped-entities */
const ReturnAndExchange = () => {
  return (
    <div className="bg-gray-50 p-8 max-w-4xl mx-auto mt-8 rounded-lg shadow-lg">
      <h1 className="text-5xl font-bold text-center text-gray-800 mb-10">Return & Exchange Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Return Procedure</h2>
        <p className="text-lg text-gray-600 mb-2">
          • To initiate a return, contact our customer support team.
        </p>
        <p className="text-lg text-gray-600 mb-2">
          • We will arrange for the product to be picked up from your registered address.
        </p>
        <p className="text-lg text-gray-600">
          • Once we receive the product at our registered warehouse and verify its condition, a full refund will be issued.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Product Exchange Policy</h2>
        <p className="text-lg text-gray-600 mb-2">
          We want you to be satisfied with your purchase. If you wish to exchange your product, please follow our exchange policy:
        </p>
        <div className="ml-4">
          <p className="text-lg text-gray-600 mb-2">
            • <strong>Exchange Request:</strong>
          </p>
          <p className="text-lg text-gray-600 mb-2">
            o Please contact us within 5 business days of receiving your order to request an exchange.
          </p>
          <p className="text-lg text-gray-600">
            o Specify whether you would like to exchange for another product or the same product in a different variant.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Return Address</h2>
        <p className="text-lg text-gray-600">
          If you're using your selected logistics for returns, kindly use the following address:
        </p>
        <p className="text-lg text-gray-800 font-semibold mt-4">
          Ground Floor, Barakurpa Joltanki Ghora,<br />
          Post - Ratanpur, District - Bankura,<br />
          State - West Bengal, Pin code- 722152<br />
          Mobile - 7384661181
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Return & Cancellation Policies</h2>
        <p className="text-lg text-gray-600 mb-2">
          Thank you for shopping with us at <a href="http://www.kansapital.com" className="text-blue-600 underline">www.kansapital.com</a>. We strive to ensure your satisfaction with every purchase. Please review our policies below:
        </p>

        <h3 className="text-xl font-semibold text-gray-700 mb-2">Return Policy</h3>
        <ul className="list-disc ml-6 text-lg text-gray-600">
          <li><strong>5-Day Free Return Policy:</strong> You have 5 days from the date of delivery to return eligible items for a full refund.</li>
          <li><strong>Refund Process:</strong> Refunds will be processed only after confirmation is received from the third-party seller regarding the product's condition.</li>
          <li><strong>Non-Returnable Items:</strong> Please note that we do not accept returns or exchanges for customized products.</li>
          <li><strong>Refund Details:</strong>
            <ul className="list-disc ml-6">
              <li>Original shipping charges are non-refundable.</li>
              <li>A shipping fee of ₹80 per kg will be deducted for returned products.</li>
            </ul>
          </li>
          <li><strong>Return Eligibility:</strong>
            <ul className="list-disc ml-6">
              <li>Your item must be unused, in the same condition that you received it, with tags attached, and in its original packaging.</li>
              <li>You must provide the receipt or proof of purchase.</li>
            </ul>
          </li>
        </ul>

        <h3 className="text-xl font-semibold text-gray-700 mt-6 mb-2">Cancellation Policy</h3>
        <ul className="list-disc ml-6 text-lg text-gray-600">
          <li><strong>Cancellation Before Dispatch:</strong>
            <ul className="list-disc ml-6">
              <li>We accept order changes or cancellation requests at any time before we dispatch your order.</li>
              <li>Unfortunately, once the shipment is out of our warehouse for delivery, we cannot cancel the order. Some orders are processed immediately for shipping, making cancellation impractical.</li>
            </ul>
          </li>
          <li><strong>Refund Process:</strong>
            <ul className="list-disc ml-6">
              <li>For prepaid orders cancelled before delivery, refunds will be initiated within 7 working days.</li>
            </ul>
          </li>
        </ul>
      </section>

      <div className="text-center mt-10">
        <p className="text-lg text-gray-600">
          For any further assistance or queries regarding our return and refund policy, please contact our customer support team. We are here to help!
        </p>
      </div>
    </div>
  );
};

export default ReturnAndExchange;

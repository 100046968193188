import { useEffect, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import Modal from "../../../Components/Shared/Modal";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { uploadCloudinary } from "../../../utils/imageUpload";
import { useForm } from "react-hook-form";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashAlt } from "react-icons/fa";
import ProductEditModal from "./ProductEditModal";
import { Live_URL } from "../../../config";

const AddProduct = () => {
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [products, setProducts] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [categories, setCategories] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10); // Items per page
  const [totalItems, setTotalItems] = useState(0);

  const { register, handleSubmit, reset } = useForm();
  const token = localStorage.getItem("token");

  const processPriceInput = (input) => {
    if (input !== "") {
      const pairs = input?.split(",");
      const priceArray = [];
      pairs.forEach((pair) => {
        const [size, price] = pair.split(":");
        const priceObject = {};
        priceObject[size?.trim()] = parseInt(price?.trim());
        priceArray.push(priceObject);
      });
      return priceArray;
    } else return [];
  };

  const onSubmit = async (data) => {
    const name = data.name;
    const description = data.description;
    const price = parseInt(data.price);
    const category = data.category;
    const couponCode = data.couponCode;
    const AmazonLink = data.amazonLink;
    const priceBySize = processPriceInput(data?.size); //convert size & price array of objects
    // console.log(priceBySize);

    const postData = {
      name,
      category,
      price,
      description,
      priceBySize,
      couponCode,
      links,
        AmazonLink,
    };
    // console.log(postData);

    try {
      const response = await axios.post(`${Live_URL}/api/product`, postData, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        toast.success("Product added successfully");
        reset();
        closeModal();
        setLinks([]);
        setDataUpdated((prev) => !prev); // Toggle the state to trigger useEffect
      } else {
        toast.error("Failed to added product");
      }
    } catch (error) {
      toast.error("Error:", error);
    }
  };

  // upload image in  Cloudinary
  const handleFileChange = async (e) => {
    try {
      const selectedFiles = Array.from(e.target.files);
      setIsLoading(true);
      let arr = [];

      // Upload each file to Cloudinary
      for (let i = 0; i < selectedFiles.length; i++) {
        const data = await uploadCloudinary(selectedFiles[i]);
        arr.push(data);
      }

      // Update the links state with the URLs of the uploaded images
      setLinks((prevLinks) => [...prevLinks, ...arr]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  // remove image from Cloudinary
  const handleRemoveFile = async (publicId) => {
    try {
      const response = await axios.delete(`${Live_URL}/api/deleteImage`, {
        data: { publicId },
      });
      if (response.status === 200) {
        const filterImage = links.filter((link) => link.publicId !== publicId);
        setLinks(filterImage);
        toast.success("Image deleted successfully");
      } else {
        toast.error("Failed to delete image");
      }
    } catch (error) {
      toast.error("Error deleting image:", error);
    }
  };

  // Delete category with images by id
  const handleDeleteById = async (id) => {
    try {
      const response = await axios.delete(`${Live_URL}/api/product/${id}`, {
        headers: { authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        toast.success("Deleted successfully");
        setDataUpdated((prev) => !prev); // Toggle the state to trigger useEffect
        // Also delete comments
        await axios.delete(`${Live_URL}/api/comment/${id}`);
      } else {
        toast.error("Failed to delete");
      }
    } catch (error) {
      toast.error("Error deleting:", error);
    }
  };

  // Update product
  const handleUpdateById = (id) => {
    openModal2();
    const findProduct = products?.find((c) => c?._id === id);
    setEditProduct(findProduct);
  };

  const fetchCategories = async () => {
    try {
      const res = await axios.get(`${Live_URL}/api/category/name`);
      setCategories(res.data);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axios.get(`${Live_URL}/api/products`, {
          params: {
            page,
            limit,
          },
        });
        setProducts(res.data?.data?.reverse());
        setTotalPages(res.data?.totalPages);
        setTotalItems(res.data?.totalItems);
      } catch (error) {
        console.error("Failed to fetch products:", error);
      }
    };

    fetchProducts();
    fetchCategories();
  }, [page, limit, dataUpdated]);

  useEffect(() => {
    document.title = "Dashboard | Products";
  }, []);

  const convertArrayToString = (priceArray) => {
    // Initialize an empty array to store the individual size-price strings
    const priceStrings = priceArray.map((priceObject) => {
      // Get the size and price from the object
      const size = Object.keys(priceObject)[0];
      const price = priceObject[size];
      // Return the formatted string
      return `${size}:${price}`;
    });

    // Join the individual strings with a comma and a space
    return priceStrings.join(", ");
  };

  return (
    <div className="mx-4">
      <Toaster />
      <>
        <button
          onClick={openModal}
          className="bg-mainColor text-white btn text-lg hover:bg-darkColor rounded-lg"
        >
          Add New Product
        </button>

        <Modal isOpen={isModalOpen} onClose={closeModal}>
          {/* <h2 className="text-xl font-bold">Add Product</h2> */}
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="form-control">
              <label className="label">
                <span className="label-text text-lg font-medium ">Name:</span>
              </label>
              <input
                {...register("name", { required: true })}
                type="text"
                name="name"
                placeholder="name here"
                className="input input-bordered text-lg"
                required
              />
            </div>

            <div className="flex sm:flex-col md:flex-row justify-between gap-4">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-lg font-medium ">
                    Category
                  </span>
                </label>
                <select
                  {...register("category", { required: true })}
                  name="category"
                  className="select select-bordered w-full  text-lg"
                >
                  <option disabled selected>
                    Select category
                  </option>
                  {categories.map((c) => (
                    <option key={c._id} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="form-control w-full"
                title="Default price: 500 | xl/md: 500"
              >
                <label className="label">
                  <span className="label-text text-lg font-medium ">
                    Price*{" "}
                    <span className="text-base font-normal">
                      {"(Default price)"}
                    </span>
                  </span>
                </label>
                <input
                  {...register("price", { required: true })}
                  type="number"
                  name="price"
                  placeholder="price here"
                  className="input input-bordered text-lg"
                  required
                />
              </div>
            </div>

            <div className="flex sm:flex-col md:flex-row justify-between gap-4">
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-lg font-medium ">
                    Size With Price*
                  </span>
                </label>
                <input
                  {...register("size", { required: true })}
                  type="text"
                  name="size"
                  placeholder="Ex: xl:600, md:250, sm:100"
                  className="input input-bordered text-lg"
                />
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-lg font-medium ">
                    Have Coupon
                  </span>
                </label>
                <input
                  {...register("couponCode", { required: false })}
                  type="text"
                  name="couponCode"
                  placeholder="If have Coupon"
                  className="input input-bordered text-lg"
                />
              </div>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text text-lg font-medium">
                  Amazon Buy Link:
                </span>
              </label>
              <input
                {...register("amazonLink", { required: false })}
                type="url"
                name="amazonLink"
                placeholder="Enter Amazon product link"
                className="input input-bordered text-lg"
              />
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text text-lg font-medium ">
                  Images: *
                </span>
                <span className="label-text">
                  Max: upload 4-5 images / Size: 1MB per
                </span>
              </label>
              <input
                type="file"
                onChange={handleFileChange}
                className="file-input file-input-bordered w-full max-w-xs text-lg"
                multiple
              />

              <div className="avatar gap-2 mt-2">
                {links &&
                  links.map((link, index) => (
                    <div key={index} className="w-14 rounded">
                      <img src={link.url} alt={`file preview ${index}`} />
                      <span
                        className="bottom-1 cursor-pointer absolute text-2xl text-red-600"
                        onClick={() => handleRemoveFile(link.publicId)}
                      >
                        <MdDeleteForever />
                      </span>
                    </div>
                  ))}
                {isLoading && (
                  <span className="text-green-700 font-semibold">
                    Uploading...
                  </span>
                )}
              </div>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text text-lg font-medium ">
                  Description
                </span>
              </label>
              <textarea
                {...register("description", { required: true })}
                name="description"
                className="textarea textarea-bordered textarea-lg px-2 py-0"
                placeholder="Bio"
              ></textarea>
            </div>

            <div className="mt-6 mx-auto">
              <button
                type="submit"
                disabled={isLoading && true}
                className="btn text-xl bg-mainColor"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>
      </>

      <>
        <div className="overflow-x-auto  shadow  mt-4 rounded-md bg-white">
          <table className="table table-sm">
            {/* head */}
            <thead>
              <tr>
                <th className="font-semibold text-lg">Image</th>
                <th className="font-semibold text-lg">Name</th>
                <th className="font-semibold text-lg">Category</th>
                <th className="font-semibold text-lg">Size&Price</th>
                <th className="font-semibold text-lg">Coupon</th>
                <th className="font-semibold text-lg">Price</th>
                <th className="font-semibold text-lg">Action</th>
              </tr>
            </thead>
            <tbody>
              <>
                {products.length > 0 ? (
                  products.map((product) => (
                    <tr key={product._id} className="text-lg">
                      <td>
                        <div className="flex items-center gap-3">
                          <div className="avatar">
                            <div className="mask rounded w-16 ">
                              <img
                                className="object-cover"
                                src={product?.links[0]?.url}
                                alt="Photo"
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{product?.name}</td>
                      <td>{product?.category}</td>
                      <td>
                        {product?.priceBySize?.length > 0
                          ? convertArrayToString(product?.priceBySize)
                          : ""}
                      </td>
                      {/* <td>{product?.size === "" ? "" : product?.size?.join(",")}</td> */}
                      <td>{product?.couponCode}</td>
                      <td>{product?.price}</td>
                      {/* <td>{
                                            product?.description.length > 60 ? product?.description.slice(0, product?.description.slice(0, 60).lastIndexOf(' ')) + '...' : product?.description
                                        }</td> */}
                      <td className="flex gap-2 items-center">
                        <button
                          onClick={() => handleUpdateById(product?._id)}
                          className="text-green-700 bg-green-200 p-2 rounded-md text-xl"
                        >
                          <FiEdit />
                        </button>
                        <button
                          onClick={() => handleDeleteById(product?._id)}
                          className="text-red-700 bg-red-200 p-2 rounded-md text-xl"
                        >
                          <FaRegTrashAlt />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <strong className="m-4 text-xl">Loading...</strong>
                )}
              </>
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <button
            className="btn btn-primary"
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
          >
            Previous
          </button>
          <span>
            Page {page} of {totalPages} - Total Items: {totalItems}
          </span>
          <button
            className="btn btn-primary"
            disabled={page === totalPages}
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      </>

      <ProductEditModal
        closeModal2={closeModal2}
        isModalOpen2={isModalOpen2}
        editProduct={editProduct}
        categories={categories}
        setDataUpdated={setDataUpdated}
      />
    </div>
  );
};

export default AddProduct;


import { useEffect, useState } from "react";
// import { uploadCloudinary } from "../../utils/imageUpload";
import { useForm } from "react-hook-form";
import useAuth from "../../Hooks/UseAuth";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Live_URL } from "../../config";

const EditProfile = () => {
  const { user, updateNameAndPhoto } = useAuth()
  const { register, handleSubmit, reset, } = useForm();
  const [userData, setUserData] = useState({});
  // const [link, setLink] = useState(null);
  // const [createLoading, setCreateLoading] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(false);
  const userEmail = user?.email


  const onSubmit = async (data) => {
    // if (data.profileName || data.phonenumber || data.location === "") return toast.error("Can't empty fields");
    const updateData = {
      profileName: data.profileName || userData?.profileName,
      phonenumber: data.phonenumber || userData?.phonenumber,
      email: data.email || userData?.email,
      location: data.location || userData?.location,
    }

    try {
      const res = await axios.patch(`${Live_URL}/api/user/${userEmail}`, updateData);
      if (res.status === 200) {
        toast.success("Profile update successfully!");
        updateNameAndPhoto(user, updateData.profileName, user?.photoURL)
          .then(() => {
            reset();
            setDataUpdated(prev => !prev); // Toggle the state to trigger useEffect
          })
          .catch(err => {
            toast.error("Failed: ", err.message);
          })
      }
    } catch (error) {
      toast.error("Failed: ", error.message);
    }
  };


  // const handleFileChange = async (e) => {
  //   const file = e.target.files[0];
  //   try {
  //     setCreateLoading(true);
  //     const publicId = userData?.profileImage?.publicId;
  //     await axios.delete(`${Live_URL}/api/deleteImage`, { data: { publicId } });

  //     const imageData = await uploadCloudinary(file);
  //     setLink(imageData.url)
  //     const res = await axios.patch(`${Live_URL}/api/user/${userEmail}`, { profileImage: imageData });
  //     if (res.status === 200) {
  //       setCreateLoading(false);
  //       toast.success("Picture update successfully");
  //       updateNameAndPhoto(user, userData?.profileName, imageData?.url)
  //         .then(() => {

  //         })
  //         .catch(err => {
  //           toast.error("Failed: ", err.message);
  //         })
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    fetch(`${Live_URL}api/user/${userEmail}`).then(res => res.json())
      .then(data => setUserData(data))
  }, [userEmail, dataUpdated])

  return (
    <>
      <Toaster />
      <div className=" items-center grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 ">
        {/* <div className="mx-auto  w-56 justify-center">
          <div className="avatar justify-center  my-5">
            <div className="w-56 rounded-full ring ring-primary relative ring-offset-base-100 ring-offset-2">
              <img src={userData?.profileImage?.url ? userData?.profileImage?.url : user?.photoURL} loading="lazy" alt="Profile" />
              {createLoading && <span className="flex justify-center ring-4 py-8 pb-12 bottom-0 bg-darkColor font-medium w-full absolute text-lg text-white" >Uploading...</span>}
            </div>
          </div>
          <div>
            <label htmlFor="message" className="block mb-1 ml-1">
              Update Profile
            </label>
            <input
              type="file"
              onChange={handleFileChange}
              placeholder="Update profile"
              className=" w-56 border-2 border-black"
            />
          </div>
        </div> */}

        <div>
          <section className="p-6 text-gray-800">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="container w-full max-w-xl p-8 mx-auto space-y-6 rounded-md shadow bg-gray-50"
            >
              <h2 className="w-full text-3xl font-bold leading-tight">
                Edit Profile
              </h2>

              <div>
                <label htmlFor="name" className="font-medium block mb-1 ml-1">Name</label>
                <input
                  {...register("profileName", { required: false })}
                  id="name"
                  type="text"
                  placeholder={userData?.profileName}

                  className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-violet-600 bg-gray-100 border-2 border-black"
                />
              </div>

              <div>
                <label htmlFor="Phone" className="font-medium block mb-1 ml-1">Phone Number</label>
                <input
                  {...register("phonenumber", { required: false })}
                  id="Phone"
                  type="text"
                  placeholder={userData?.phonenumber}

                  className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-violet-600 bg-gray-100 border border-black"
                />
              </div>
              <div>
                <label htmlFor="email" className="font-medium mb-1 ml-1 flex justify-between items-center">
                  <span>Email</span>
                  <span className={`${user?.emailVerified ? 'bg-green-200 text-green-700' : 'bg-red-200 text-red-700'} tooltip px-2 text-sm rounded-full cursor-pointer`}
                    data-tip={`${user?.emailVerified ? "Verified" : "Check your email inbox or spam"}`}
                  >
                    {user?.emailVerified ? "Verified" : "Not Verified"}
                  </span>

                </label>
                <input
                  {...register("email", { required: false })}
                  id="email"
                  type="text"
                  disabled
                  defaultValue={userData?.email}
                  className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-violet-600 bg-gray-100 border border-black"
                />
              </div>

              <div>
                <label htmlFor="location" className="block font-medium mb-1 ml-1">location</label>
                <input
                  {...register("location", { required: false })}
                  id="location"
                  type="text"
                  placeholder={userData?.location}
                  className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-violet-600 bg-gray-100 border border-black"
                />
              </div>

              <input type="submit" className="bg-mainColor hover:bg-buttonHoverColor font-medium text-xl cursor-pointer px-5 py-2 rounded-md text-darkColor" />
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default EditProfile;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa6";
import { Live_URL } from "../../../config";

const Comment = ({ id ,dataUpdated,setReview }) => {
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    fetch(`${Live_URL}/api/comment/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setProductData(data)
        setReview(data.length)
      });
  },[id, dataUpdated]);

 

  return (
    <div>
      {productData.length > 0 && productData?.map(data => <>
      <div key={data._id} className="chat chat-start my-3 ">
            
            <div className="chat-image avatar">
              <div className="w-10 rounded-full">
                <img
                  alt="Tailwind CSS chat bubble component"
                  src={data.userImage}
                />
              </div>
            </div>
            <div className="chat-bubble bg-slate-200 text-black w-10/12">
              <h1 className="font-semibold text-xl">{data?.userName}</h1>
              <h1 className="text-lg">{data.comment}</h1>
              <div className="flex">
      {Array.from({ length: 5 }, (_, index) => (
        <FaStar
          key={index}
          className={`text-2xl ${
            index < data.rating ? 'text-yellow-500' : 'text-gray-300'
          }`}
        />
      ))}
    </div>
            </div>
          </div>
      </>)}
    </div>
  );
};

export default Comment;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Slide } from "react-slideshow-image";
import {  Link} from "react-router-dom";

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  borderredes:'20px'
}

const CategoryCard = ({ data  }) => {
 

  const { links, name } = data;
  
  return (
    
    <div className="bg-cardBg pb-3  rounded-lg">
      <div className="slide-container rounded-lg">
        <Slide>
          {links.map((slideImage, index) => (
            <div key={index}>
              <div
                style={{
                  ...divStyle,
                  backgroundImage: `url(${slideImage.url})`,
                }}
                className="h-72 xl:h-[300px] md:h-[300px] lg:h-[300px] rounded-lg"
                ></div>
            </div>
          ))}
        </Slide>
      </div>
      <Link to={`/products/${name}`}>
          <h1 className="text-3xl font-bold p-2  text-mainColor" >{name}</h1>
          </Link>
    </div>
  );
};

export default CategoryCard;

/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import useAuth from "../../../Hooks/UseAuth";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Live_URL } from "../../../config";

const Review = ({ id ,setDataUpdated}) => {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  
  // eslint-disable-next-line no-unused-vars
  const [text, setText] = useState("");
  const { user } = useAuth();
  const handleRating = (rate) => {
    setRating(rate);
  };

  const postData = {
    userName: user?.displayName,
    userImage: user?.photoURL,
    comment: text,
    rating: rating,
    productId: id,
    email: user?.email,
  };
  const handelSubmit = async() => {
        try {
            const response = await axios.post(`${Live_URL}/api/comment`, postData);
            if (response.status === 200) {
                toast.success("Comment Create successfully");
                setRating();
                setRating();
                setDataUpdated(prev => !prev); // Toggle the state to trigger useEffect
            } else {
                toast.error("Failed to create category")
            }
        } catch (error) {
            toast.error('Error:', error);
        }
  };

  return (
    <div>
      <Toaster/>
      <h1 className="text-2xl font-semibold ">Give Reviews</h1>

      <div className="">
        <textarea
          onChange={(e) => {
            setText(e.target.value);
          }}
          className="textarea textarea-warning bg-slate-50 w-full xl:w-96 lg:w-96 md:w-96 sm:w-96"
          placeholder="Bio"
        ></textarea>
      </div>

      <div className="flex flex-col ">
        <div className="flex">
          {[...Array(5)].map((star, index) => {
            const ratingValue = index + 1;
            return (
              <label key={index}>
                <input
                  type="radio"
                  name="rating"
                  value={ratingValue}
                  onClick={() => handleRating(ratingValue)}
                  className="hidden"
                />
                <FaStar
                  className={`cursor-pointer text-2xl transition-colors duration-200 ${
                    ratingValue <= (hover || rating)
                      ? "text-yellow-500"
                      : "text-gray-300"
                  }`}
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
      </div>

      <div className="my-6">
        <button
          onClick={handelSubmit}
          className="bg-yellow-500 hover:bg-yellow-400 px-6 text-black py-2 rounded-md "
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Review;

/* eslint-disable no-unused-vars */
import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { Live_URL } from "../../config";

const BulkOrderForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit =async (dataForm) => {
    try {
            const response = await axios.post(`${Live_URL}api/bulkOrder`, dataForm);
      if (response.status === 200) {
        reset()
                toast.success("bulkOrder successfully");// Toggle the state to trigger useEffect
            } else {
                toast.error("Failed to added product")
            }
        } catch (error) {
            toast.error('Error:', error);
        }
  };

  return (
    <div>
       <Toaster />
      <div className="flex flex-col max-w-md p-4 rounded-md sm:p-10 bg-cardBg mx-auto my-5 text-gray-800">
        <div className="mb-6 text-center">
          <h1 className="text-4xl font-bold">Bulk Order</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-12">
          <div className="space-y-6">
            <div>
              <label htmlFor="text" className=" mb-2 text-sm">
                Full Name*
              </label>
              <input
                type="text"
                {...register("FullName", { required: true })}
                placeholder="FullName*"
                className="w-full px-3 py-2 border rounded-md bg-gray-100  "
              />
            </div>
            <div>
              <label htmlFor="email" className=" mb-2 text-sm">
                Email address
              </label>
              <input
                type="email"
                {...register("email", { required: true })}
                placeholder="leroy@jenkins.com"
                className="w-full px-3 py-2 border rounded-md bg-gray-100  "
              />
            </div>

            <div>
              <label htmlFor="number" className=" mb-2 text-sm">
                Mobile Number
              </label>
              <input
                type="number"
                {...register("mobileNumber", { required: true })}
                placeholder="type your user name"
                className="w-full px-3 py-2 border rounded-md bg-gray-100  "
              />
            </div>
            <div>
              <label htmlFor="number" className=" mb-2 text-sm">
                WhatsApp App Number*
              </label>
              <input
                type="number"
                {...register("WhatsAppAppNumber", { required: true })}
                placeholder="WhatsApp App Number"
                className="w-full px-3 py-2 border rounded-md bg-gray-100  "
              />
            </div>

            <div>
              <label htmlFor="email" className=" mb-2 text-sm">
                Required Quantity*
              </label>
              <input
                type="text"
                {...register("RequiredQuantity", { required: true })}
                placeholder="Required Quantity"
                className="w-full px-3 py-2 border rounded-md bg-gray-100  "
              />
            </div>

            <div>
              <label htmlFor="email" className=" mb-2 text-sm">
                Comment*
              </label>
              <textarea
                {...register("Comment", { required: true })}
                placeholder="Comment"
                className="w-full px-3 py-2 border rounded-md bg-gray-100  "
              ></textarea>
            </div>

            <div>
              <label htmlFor="email" className=" mb-2 text-sm">
                GSTIN
              </label>
              <input
                type="text"
                {...register("GSTIN")}
                placeholder="GSTIN"
                className="w-full px-3 py-2 border rounded-md bg-gray-100  "
              />
            </div>
            <div>
              <label htmlFor="email" className=" mb-2 text-sm">
                Country*
              </label>
              <input
                type="text"
                {...register("Country", { required: true })}
                placeholder="Country"
                className="w-full px-3 py-2 border rounded-md bg-gray-100  "
              />
            </div>
          </div>

          <input
            type="submit"
            className="w-full px-8 py-3 font-semibold rounded-md bg-yellow-500 hover:bg-yellow-600  text-black"
            value="SUBMIT"
          />
        </form>
      </div>
    </div>
  );
};

export default BulkOrderForm;

/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from "react";

const BulkModal = ({ findItems }) => {

    console.log(findItems);
  return (
    <div>
      <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle ">
        <div className="modal-box bg-slate-100">
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="btn -mt-5">x</button>
            </form>
          </div>
                  <div><h1 className="text-black">{findItems }</h1></div>
        </div>
      </dialog>
    </div>
  );
};

export default BulkModal;

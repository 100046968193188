/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import RelatedProductscard from './RelatedProductscard';
import { Live_URL } from '../../../config';

const RelatedProducts = ({category}) => {
    const [product , setProduct] = useState([])
    useEffect(() => {
        fetch(`${Live_URL}/api/product/recommendations/${category}`).then(res =>res.json())
        .then(data =>{setProduct(data)})
    },[category])
    return (
        <div>
         <div className="my-5">
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 mx-auto grid-cols-1 gap-6">
        {product?.map((data) =>
          <RelatedProductscard data={data} key={data._id} />
        )}
      </div>
    </div>   
            
        </div>
    );
};

export default RelatedProducts;
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import BulkModal from "./BulkModel";
import { MdDelete } from "react-icons/md";
import { Live_URL } from "../../../config";

const BulkOrders = () => {
  const [order, setOrder] = useState([]);
  const [findItems, setFindItems] = useState("");
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    fetch(`${Live_URL}/api/bulkOrders`)
      .then((res) => res.json())
      .then((data) => {
        setOrder(data.reverse())
      });
  }, [refresh]);

  const handelclick = (items) => {
    setFindItems(items);
    document.getElementById("my_modal_5").showModal();
  };
  const handelDelete = async(id) => {
   try {
    const response = await fetch(`${Live_URL}/api/bulkOrders/${id}`, {
      method: 'DELETE',
    });
    const data = await response.json();
   console.log(data);
   setRefresh(!refresh)
  } catch (error) {
    console.error('Error deleting item:', error);
  }
  };


  return (
    <div>
      <div>
        <div className="mx-4 mt-8">
          <h1 className="text-3xl  font-bold mb-6">BulkOrders</h1>

          {
            order.length >0 ?  <div className="overflow-x-auto  shadow  mt-4 rounded-md bg-white">
            <table className="table w-full table-sm">
              <thead>
                <tr className="font-semibold text-base">
                  <th>FullName</th>
                  <th>RequiredQuantity</th>
                  <th>WhatsAppNumber</th>
                  <th>mobileNumber</th>
                  <th>Email</th>
                  <th>Country</th>
                  <th>GSTIN</th>
                  <th>Comment</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {order?.map((o) => (
                  <tr key={o.id}>
                    <td>{o?.FullName}</td>
                    <td>{o?.RequiredQuantity}</td>
                    <td>{o?.WhatsAppAppNumber}</td>
                    <td>{o?.mobileNumber}</td>
                    <td>{o?.email}</td>
                    <td>{o?.Country}</td>
                    <td>{o?.GSTIN}</td>
                    <td>
                      <GrView onClick={() => handelclick(o?.Comment)} />
                    </td>
                    <td>
                      <MdDelete
                        size={22}
                        color="red"
                        onClick={() => handelDelete(o?._id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div> : <>
                <h1 className="text-2xl font-bold text-black text-center">
            Dont Have Data !!!
            </h1>
              
              </>
          }

         
        </div>
      </div>
      <BulkModal findItems={findItems} />
    </div>
  );
};

export default BulkOrders;

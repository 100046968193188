import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../../../Components/Shared/Modal";
import EditModal from "./EditModal";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { uploadCloudinary } from "../../../utils/imageUpload";
import { Live_URL } from "../../../config";

const WashingKit = () => {
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [editCategory, setEditCategory] = useState({});
    const [dataUpdated, setDataUpdated] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const openModal2 = () => setIsModalOpen2(true);
    const closeModal2 = () => setIsModalOpen2(false);

    const { register, handleSubmit, reset, } = useForm()
    const token = localStorage.getItem("token");

    const onSubmit = async (data) => {
        setIsLoading(true);

        try {
            const imageData = await uploadCloudinary(file);
            const postData = {
                name: data.name,
                size: data.size,
                price: +data.price,
                image: imageData
            }
            const response = await axios.post(`${Live_URL}/api/washingKit`, postData, {
                headers: { authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                toast.success(" added successfully");
                reset();
                setIsLoading(false);
                closeModal();
                setDataUpdated(prev => !prev); // Toggle the state to trigger useEffect
            } else {
                setIsLoading(false);
                toast.error("Failed to added ")
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('Error:', error);
        }
    }

    // upload image in  Cloudinary
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };


    // Delete category with images by id
    const handleDeleteById = async (id) => {
        try {
            const response = await axios.delete(`${Live_URL}/api/washingKit/${id}`, {
                headers: { authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                toast.success("Deleted successfully");
                setDataUpdated(prev => !prev); // Toggle the state to trigger useEffect
            } else {
                toast.error("Failed to delete")
            }
        } catch (error) {
            toast.error('Error deleting:', error);
        }
    }

    // Update category
    const handleUpdateById = (id) => {
        openModal2();
        const findCategory = categories?.find(c => c?._id === id);
        setEditCategory(findCategory);
    }

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const res = await axios.get(`${Live_URL}/api/washingKit`);
                setCategories(res.data);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        };

        fetchCategories();
    }, [dataUpdated])

    useEffect(() => {
        document.title = "Dashboard | Add Washing Kit";
    }, []);
    return (
        <>
            <Toaster />
            <div className="mx-4 md:mx-auto lg:w-3/4">
                <>
                    <div>
                        {categories.length > 0 ? <button disabled onClick={openModal} className="bg-mainColor text-white btn text-lg hover:bg-darkColor rounded-lg">
                            Add Washing Kit
                        </button>
                            :
                            <button onClick={openModal} className="bg-mainColor text-white btn text-lg hover:bg-darkColor rounded-lg">
                                Add Washing Kit
                            </button>
                        }
                        <p>Only added 1 washing kit not 2</p>
                    </div>

                    <Modal isOpen={isModalOpen} onClose={closeModal}>
                        {/* <h2 className="text-xl font-bold">Add Product</h2> */}
                        <form onSubmit={handleSubmit(onSubmit)} className="">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text text-lg font-medium ">Name: *</span>
                                </label>
                                <input {...register("name", { required: true })} type="text" name="name" placeholder="Name here..." className="input input-bordered text-lg" required />
                            </div>


                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text text-lg font-medium ">Images: *</span>
                                    <span className="label-text">Max Size: 1MB per</span>
                                </label>
                                <input type="file" onChange={handleFileChange} className="file-input file-input-bordered w-full max-w-xs text-lg" />

                                <div className="avatar gap-2 mt-2">
                                    {imagePreview && <div className="w-14 rounded">
                                        <img src={imagePreview} alt={`file preview`} />
                                        <span className="bottom-1 cursor-pointer absolute text-2xl text-red-600" onClick={() => setImagePreview(null)}><MdDeleteForever /></span>
                                    </div>}
                                </div>
                            </div>
                            <div className="flex sm:flex-col md:flex-row justify-between gap-4">
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-lg font-medium ">Size</span>
                                    </label>
                                    <input {...register("size", { required: true })}
                                        type="text"
                                        name="size"
                                        placeholder="Enter size"
                                        className="input input-bordered text-lg"
                                    />
                                </div>

                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text text-lg font-medium ">Price</span>
                                    </label>
                                    <input {...register("price", { required: true })} type="number" name="price" placeholder="Enter price" className="input input-bordered text-lg" />
                                </div>
                            </div>

                            <div className="mt-6 mx-auto">
                                <button type="submit" disabled={isLoading && true} className="btn text-xl bg-mainColor text-white hover:bg-darkColor">Submit</button>
                            </div>
                        </form>
                    </Modal>
                </>

                <>
                    <div className="overflow-x-auto  shadow  mt-4 rounded-md bg-white">
                        <table className="table table-sm">
                            {/* head */}
                            <thead>
                                <tr>
                                    <th className="font-semibold text-lg">Image</th>
                                    <th className="font-semibold text-lg">Name</th>
                                    <th className="font-semibold text-lg">Price</th>
                                    <th className="font-semibold text-lg">Size</th>
                                    <th className="font-semibold text-lg">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    {categories.length > 0 ? categories.map(category =>
                                        <tr key={category._id} className="text-lg">
                                            <td>
                                                <div className="flex items-center gap-3">
                                                    <div className="avatar">
                                                        <div className="mask rounded w-16 ">
                                                            <img className="object-cover" src={category?.image?.url} alt="Photo" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{category.name}</td>
                                            <td>{category.price}</td>
                                            <td>{category.size}</td>
                                            <td className="flex gap-2 items-center">
                                                <button onClick={() => handleUpdateById(category._id)} className="text-green-700 bg-green-200 p-2 rounded-md text-xl"><FiEdit /></button>
                                                <button onClick={() => handleDeleteById(category._id)} className="text-red-700 bg-red-200 p-2 rounded-md text-xl"><FaRegTrashAlt /></button>
                                            </td>
                                        </tr>
                                    ) : <strong className="m-4 text-xl">Loading...</strong>}

                                </>
                            </tbody>
                        </table>
                    </div>
                </>
            </div>
            <EditModal
                closeModal2={closeModal2}
                isModalOpen2={isModalOpen2}
                editCategory={editCategory}
                setDataUpdated={setDataUpdated}
            />
        </>
    );
};

export default WashingKit;
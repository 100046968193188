/* eslint-disable no-unused-vars */
import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { IoLogoFacebook, IoLogoYoutube, IoLogoInstagram } from "react-icons/io";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer className="footer p-10 bg-[#04103a] text-white rounded-xl shadow-lg shadow-gray-300">
        <aside>
          <svg
            width="50"
            height="50"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            className="fill-current text-white"
          >
            <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
          </svg>
          <p>Kasapital</p>
          <p>Sarenga, Bankura West Bengal, 722150</p>
          <p>Phone: +91 7872282450</p>
          <p>E-mail: info@kansapital.com</p>
          <p>GSTIN: 19HTDPM2142E1ZS</p>
        </aside>
        <nav>
          <h6 className="footer-title">Customer service</h6>
          <Link to="/Return&Exchange" className="link link-hover text-white">Return & Exchange</Link>
          <Link to='/BulkOrder' className="link link-hover text-white">Bulk Order</Link>
        </nav>
        <nav>
          <h6 className="footer-title">Company</h6>
          <Link to='/AboutUs' className="link link-hover text-white">About us</Link>
          <Link to="/contact" className="link link-hover text-white">Contact us</Link>
          <Link to='/TermsConditions' className="link link-hover text-white">Terms & Conditions</Link>
          <Link to='/PrivacyPolicy' className="link link-hover text-white">Privacy Policy</Link>
          <Link to='/ShippingPolicy' className="link link-hover text-white">Shipping Policy</Link>
        </nav>
        <nav>
          <h6 className="footer-title">Social media</h6>
          <div className="grid grid-cols-3 gap-5">
            <a href="https://www.facebook.com/share/XCWC1TEqQLnHGdZm/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer" className="link link-hover text-white">
              <IoLogoFacebook size={20} />
            </a>
            <a href="https://www.instagram.com/kansapital_offically?igsh=NDE0eW16bzg3azgz" target="_blank" rel="noopener noreferrer" className="link link-hover text-white">
              <IoLogoInstagram size={20} />
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="link link-hover text-white">
              <IoLogoYoutube size={20} />
            </a>
          </div>
          <h1 className="text-xl text-white mt-3">
            @ KasaPital 2024
          </h1>
        </nav>
      </footer>
    </div>
  );
};

export default Footer;

/* eslint-disable react/prop-types */
import { useForm } from 'react-hook-form';
import Modal from '../../../Components/Shared/Modal';
import { useEffect, useState } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { uploadCloudinary } from '../../../utils/imageUpload';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Live_URL } from '../../../config';

const EditModal = ({ isModalOpen2, closeModal2, editCategory, setDataUpdated }) => {
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, reset } = useForm()
    // console.log(file);

    useEffect(() => {
        if (editCategory && editCategory?.image) {
            setFile({ ...editCategory?.image });
            setImagePreview(editCategory?.image?.url)
        }
    }, [editCategory]);

    const token = localStorage.getItem("token");
    console.log(file);

    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            const imageData = file.url ? file : await uploadCloudinary(file);
            const postData = {
                name: data.name,
                size: data.size,
                price: +data.price,
                image: imageData
            }

            const response = await axios.patch(`${Live_URL}/api/washingKit/${editCategory?._id}`, postData, {
                headers: { authorization: `Bearer ${token}` }
            });
            if (response.status === 200) {
                toast.success("Slider updated successfully");
                reset();
                setIsLoading(false);
                closeModal2();
                setDataUpdated(prev => !prev); // Toggle the state to trigger useEffect
            } else {
                setIsLoading(false);
                toast.error("Failed to update")
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('Error:', error);
        }
    }

    // upload image in  Cloudinary
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleRemoveFile = async () => {
        const publicId = editCategory?.image?.publicId;
        try {
            setIsLoading(true);
            const response = await axios.delete(`${Live_URL}/api/deleteImage`, { data: { publicId } });
            if (response.status === 200) {
                setIsLoading(false);
                toast.success("Delete successfully");
                setImagePreview(null)
                setFile(null);
            } else {
                toast.error("Failed to delete image")
            }
        } catch (error) {
            toast.error('Error deleting image:', error);
        }
    };


    return (
        <>
            <Modal isOpen={isModalOpen2} onClose={closeModal2}>
                {/* <h2 className="text-xl font-bold">Add Product</h2> */}
                <form onSubmit={handleSubmit(onSubmit)} className="">
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text text-lg font-medium ">Name: *</span>
                        </label>
                        <input {...register("name", { required: true })} defaultValue={editCategory?.name} type="text" name="name" placeholder="Name here..." className="input input-bordered text-lg" required />
                    </div>


                    <div className="form-control">
                        <label className="label">
                            <span className="label-text text-lg font-medium ">Images: *</span>
                            <span className="label-text">Max Size: 1MB per</span>
                        </label>
                        <input type="file" onChange={handleFileChange} disabled={imagePreview && true} className="file-input file-input-bordered w-full max-w-xs text-lg" multiple />

                        <div className="avatar gap-2 mt-2">
                            {imagePreview && <div className="w-14 rounded">
                                <img src={imagePreview} alt={`file preview`} />
                                <span className="bottom-1 cursor-pointer absolute text-2xl text-red-600" onClick={() => handleRemoveFile()}><MdDeleteForever /></span>
                            </div>}

                        </div>
                    </div>

                    <div className="flex sm:flex-col md:flex-row justify-between gap-4">
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text text-lg font-medium ">Size</span>
                            </label>
                            <input {...register("size", { required: true })}
                                defaultValue={editCategory?.size}
                                type="text"
                                name="size"
                                placeholder="Enter size"
                                className="input input-bordered text-lg"
                            />
                        </div>

                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text text-lg font-medium ">Price</span>
                            </label>
                            <input {...register("price", { required: true })}
                                defaultValue={editCategory?.price}
                                type="number" name="price" placeholder="Enter price" className="input input-bordered text-lg" />
                        </div>
                    </div>

                    <div className="mt-6 mx-auto">
                        <button type="submit" disabled={isLoading && true} className="btn text-xl bg-mainColor text-white hover:bg-darkColor">Submit</button>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export default EditModal;
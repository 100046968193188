/* eslint-disable no-unused-vars */
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import CategoryAllProductcard from '../Product/CategoryAllProduct/CategoryAllProductcard';

const Search = () => {
    const data = useLoaderData()
 
    return (
    
            <div className="my-5">
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 mx-auto grid-cols-1 gap-6">
        {data.map((data) =>
          <CategoryAllProductcard data={data} key={data._id} />
        )}
      </div>
        </div>
    );
};

export default Search;
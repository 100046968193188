/* eslint-disable react/no-unescaped-entities */
const ShippingPolicy = () => {
  return (
    <div className="bg-gray-100 p-6 max-w-4xl mx-auto mt-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-4">Shipping Policy</h2>
      <p className="text-gray-600 mb-6">
        Thank you for choosing <a href="https://www.kansapital.com/" className="text-blue-500 hover:text-blue-700">https://www.kansapital.com/</a>. We aim to provide a seamless shipping experience. Please review our shipping policy below:
      </p>

      <div className="mb-8">
        <h3 className="text-xl font-semibold text-gray-700 mb-2">Delivery Locations:</h3>
        <p className="text-gray-600">We currently deliver across India, including all metros and major non-metro cities.</p>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold text-gray-700 mb-2">Shipping Timelines:</h3>
        <ul className="list-disc list-inside text-gray-600 space-y-2">
          <li>
            <strong>Order Processing:</strong> Sellers typically pack orders within 2 business days.
          </li>
          <li>
            <strong>Dispatch Notification:</strong> Once your order is packed, our logistics partners are promptly notified for delivery.
          </li>
          <li>
            <strong>Dispatch Timeline:</strong> We normally dispatch all our orders within 5-6 days of receiving the order. Depending on the geographic location of the customer, it may take about 4 to 12 working days to deliver the product to the customer's door.
          </li>
          <li>
            <strong>Tracking Your Order:</strong> You can track the shipping status on our website.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ShippingPolicy;
